
import { Options, Vue } from 'vue-class-component';
import menu from '@/mock/menu'

@Options({
    mounted(){
        console.log('dashboard')
        this.$store.commit('changeSubMenu', menu.dashboardSub)
        console.log(this.$store.state)
    }
})

export default class DashboardLayout extends Vue {}
